import { Box, Typography, Button, List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useEffect } from "react";
import { SUPPORTED_WALLETS } from "src/connectors";
import { UserContext } from "src/context/User";
import { useWeb3React } from "@web3-react/core";

const useStyles = makeStyles((theme) => ({
  metamaskhead: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    background: "#0b0c19",
    borderRadius: "14px",
    padding: "0px 0px 0px 12px",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "24px",
    "& h5": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "20px",
      textTransform: "capitalize",
      color: "#ffffff",
    },
    "&:hover": {
      background: "#383858",
    },
  },
  imgbox: {
    "& figure": {
      overflow: "hidden",
      "& img": {
        // maxHeight: "100%",
        maxWidth: "100%",
        height: "auto",
        width: "auto",
        display: "block",
        borderRadius: "25px",
      },
    },
  },
  grid: {
    padding: "10px",
  },
  logintext: {
    marginTop: "60px",
    "& h5": {
      color: theme.palette.secondary.main,
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  metamaskhead: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    background: "#0b0c19",
    borderRadius: "14px",
    padding: "0px 0px 0px 12px",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "24px",
    "& h5": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "20px",
      textTransform: "capitalize",
      color: "#ffffff",
    },
    "&:hover": {
      background: "#383858",
    },
  },
  walletconnect: {
    display: "flex",
    alignItems: "center",
    background: "#F4F4F4",
    borderRadius: "14px",
    marginLeft: "0 !important",
    justifyContent: "space-between",
    marginTop: "10px !important",
    padding: "10px",
    width: "100%",
    "& h5": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "20px",
      textTransform: "capitalize",
      color: "#5697F5",
    },
  },
  signup: {
    "& h5": {
      color: theme.palette.secondary.main,
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "130%",
      "& button": {
        color: theme.palette.primary.main,
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "130%",
      },
    },
  },
}));
const ConnectWallet = ({ onClose, open }) => {
  const classes = useStyles();
  const user = useContext(UserContext);
  const { account } = useWeb3React();

  useEffect(() => {
    if (account) {
      onClose();
    }
  }, [account]);

  return (
    <Dialog
      open={open}
      className='walletdailog'
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title' className='dailogTitle'>
        <span style={{ fontFamily: "'Orbitron', sans-seri" }}>
          Select a Wallet
        </span>
      </DialogTitle>
      <DialogContent className='loginModal'>
        <DialogContentText id='alert-dialog-description'>
          <Box className='walletlist'>
            <List>
              <ListItem>
                {SUPPORTED_WALLETS.map((item, i) => {
                  return (
                    <Button
                      key={i}
                      className={classes.metamaskhead}
                      // className="ak"
                      onClick={() => {
                        localStorage.removeItem("walletName");
                        localStorage.setItem("walletName", item.name);
                        user.connectWallet(item.data);
                      }}
                    >
                      <Box>
                        <Typography variant='h5'> {item.data.name}</Typography>
                      </Box>
                      <Box width='50px'>
                        <img src={item.data.iconName} alt='' width='70%' />
                      </Box>
                    </Button>
                  );
                })}
              </ListItem>
            </List>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions className='dailogFooter'>
        <a
          onClick={onClose}
          color='primary'
          autoFocus
          style={{ cursor: "pointer", fontFamily: "'Orbitron', sans-seri" }}
        >
          Close
        </a>
      </DialogActions>
    </Dialog>
  );
};

export default ConnectWallet;
