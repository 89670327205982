import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "  #15172D",
    color: "#fff",
  },
  MainLayout: {
    minHeight: "calc(100vh - 415px)",
    position: "relative",
  },
  shade1: {
    top: "10%",
    left: "-20%",
    right: "auto",
    width: "500px",
    bottom: "auto",
    filter: "blur(100px)",
    height: "500px",
    opacity: " 0.55",
    zIndex: -1,
    position: "fixed",
    borderRadius: "1000px",
    backgroundImage:
      "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(187 184 215) 0%, rgb(74 65 197) 95.78%)",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  shade2: {
    top: "auto",
    bottom: " 10%",
    left: "auto",
    right: "-5%",
    width: "200px",
    filter: "blur(100px)",
    height: "200px",
    opacity: "0.55",
    // zIndex: 2,
    position: "fixed",
    borderRadius: "1000px",
    backgroundImage:
      "radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(187 184 215) 0%, rgb(74 65 197) 95.78%)",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const MainLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <TopBar />
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>
        <div className={classes.shade1}></div>
        <div className={classes.shade2}></div>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
