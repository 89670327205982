// let baseurl = "http://172.16.2.11:3058";
let baseurl = "https://node-safesquid.mobiloitte.com";
// let baseurl = "http://172.16.1.246:1896";//tanya mam
// let baseurl = "http://172.16.1.172:1896";

let user = `${baseurl}/api/v1/user`;
let nftData = `${baseurl}/api/v1/nft`;
let order = `${baseurl}/api/v1/order`;
let staticData = `${baseurl}/api/v1/static`;
let orderNft = `${baseurl}/api/v1/order`;
let bidNft = `${baseurl}/api/v1/bid`;
let room = `${baseurl}/api/v1/room`;
let historyNft = `${baseurl}/api/v1/history`;
let nft = `${baseurl}/api/v1/nft`;
let admin = `${baseurl}/api/v1/admin`;
export const websiteName =
  window.location.protocol + "//" + window.location.host;

export const socketURL = "wss://node-safesquid.mobiloitte.com";
const apiConfig = {
  //USER
  uploadFile: `${user}/uploadFile`,
  connectWallet: `${user}/connectWallet`,
  profile: `${user}/profile`,
  updateProfile: `${user}/updateProfile`,
  listTournament: `${user}/listTournament`,
  listRound: `${user}/listRound`,
  //Nft
  createNFT: `${nftData}/createNFT`,
  listNFT: `${nftData}/listNFT`,
  stackUnstack: `${nftData}/stackUnstack`,
  nft: `${nftData}/nft`,
  //Static
  staticContentList: `${staticData}/staticContentList`,

  //bid
  createOrder: `${orderNft}/createOrder`,
  placeOrderList: `${orderNft}/placeOrderList`,
  viewOrder: `${orderNft}/viewOrder/`,
  createBid: `${bidNft}/createBid`,
  rejectBid: `${bidNft}/rejectBid`,
  nftBidList: `${bidNft}/nftBidList`,

  //Order/
  userOrderList: `${order}/userOrderList`,
  buyOrder: `${order}/buyOrder`,
  buyUserOrderList: `${order}/buyUserOrderList`,
  marketPlaceDashboard: `${order}/marketPlaceDashboard`,
  sendOrderToUser: `${order}/sendOrderToUser`,
  cancelOrder: `${order}/cancelOrder`,
  soldOrderList: `${order}/soldOrderList`,

  //historyNft

  recentHistoryList: `${historyNft}/recentHistoryList`,
  viewNftHistory: `${historyNft}/viewNftHistory`,

  //room management

  onlineUser: `${room}/onlineUser`,
  offlineUser: `${room}/offlineUser`,
  getStack: `${room}/getStack`,
  joinGameRoom: `${room}/joinGameRoom`,
  activeRoomList: `${room}/activeRoomList`,
  myBattele: `${room}/myBattele/`,
  startPlay: `${room}/startPlay`,
  battlesByRound: `${room}/battlesByRound`,
  addPoolCollection: `${room}/addPoolCollection`,
  viewGameRoom: `${room}/viewGameRoom/`,
  activeRound: `${room}/activeRound/`,
  getTime: `${room}/getTime`,
  winnerHistory: `${room}/winnerHistory`,
  userAllDetails: `${room}/userAllDetails/`,
  applyOffer: `${room}/applyOffer`,
  updateOfferSpin: `${room}/updateOfferSpin`,
  getGameBoostStacking: `${room}/getGameBoostStacking`,
  addBoostStacking: `${room}/addBoostStacking`,
  getBoostStacking: `${room}/getBoostStacking`,
  removeBoostStacking: `${room}/removeBoostStacking`,
  boostApply: `${room}/boostApply`,

  // nft
  listNftType: `${nft}/listNftType`,
  // admin
  login: `${admin}/login`,
  forgotPassword: `${admin}/forgotPassword`,
  resetPassword: `${admin}/resetPassword`,
  getTournamentList: `${admin}/getTournamentList`,
  getGameBoostStacking: `${admin}/getGameBoostStacking`,
  viewGameBoostStacking: `${admin}/viewGameBoostStacking/`,
  updateGameBoostStacking: `${admin}/updateGameBoostStacking`,
  viewTournament: `${admin}/viewTournament/`,
  updateTournament: `${admin}/updateTournament`,
  addSubAdmin: `${admin}/addSubAdmin`,
  userList: `${admin}/userList`,
  viewUser: `${admin}/viewUser/`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
};
export default apiConfig;
