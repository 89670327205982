import React, { useState, useContext, useEffect } from "react";

import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import { UserContext } from "src/context/User";
import { toast } from "react-toastify";

import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineInstagram } from "react-icons/ai";
import { useWeb3React } from "@web3-react/core";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    borderTop: "1px solid #393939",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(4),
    },
    "& ul": {
      padding: "0",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "400",
      color: "#ffffff",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    "& a": {
      color: " #D5D5D5",
      fontSize: "16px",
      textDecoration: " none",
      "&:hover": {
        color: "#da4378",
      },
      "& svg": {
        color: "#da4378",
        fontSize: "20px",
        marginRight: "10px",
        "&:hover": {
          color: "#fff",
        },
      },
    },
  },
  colortext: {
    cursor: "pointer",
    paddingLeft: "0px",
    color: "#D5D5D5",
    fontSize: "16px",
    "&:hover": {
      color: "#da4378",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();

  const { account, library, chainId } = useWeb3React();
  const toastmsg = () => {
    toast.warn("Please connect your wallet");
  };
  const toastmsgprofile = () => {
    toast.warn("Please connect your wallet");
  };
  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item lg={3} sm={4} xs={12}>
              <Box>
                <img src="images/logo.png" alt="" />
              </Box>
            </Grid>
            <Grid item lg={9} sm={8} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={3} sm={6} xs={6}>
                  <Box>
                    <Typography
                      className="footerTitle"
                      variant="h3"
                      style={{ color: "#ffffff" }}
                    >
                      Products
                    </Typography>
                    <List className="listingFooter">
                      <ListItem
                        className={classes.colortext}
                        onClick={() => {
                          history.push({
                            pathname: "/main-game",
                            search: "maingame",
                          });
                        }}
                      >
                        Main Game
                      </ListItem>
                      <ListItem
                        className={classes.colortext}
                        // to="/mini-game"
                        // component={Link}
                        onClick={() => {
                          history.push({
                            pathname: "/mini-game",
                            search: "minigame",
                          });
                        }}
                      >
                        Mini Game
                      </ListItem>
                    </List>{" "}
                  </Box>
                </Grid>
                <Grid item lg={3} sm={6} xs={6}>
                  <Box>
                    <Typography
                      className="footerTitle"
                      variant="h3"
                      style={{ color: "#ffffff" }}
                    >
                      Info
                    </Typography>
                    <List className="listingFooter">
                      <ListItem
                        to="/team"
                        component={Link}
                        style={{ cursor: "pointer", paddingLeft: "0px" }}
                      >
                        Team
                      </ListItem>
                      <ListItem
                        to="/about"
                        component={Link}
                        style={{ cursor: "pointer", paddingLeft: "0px" }}
                      >
                        About Us
                      </ListItem>
                      <ListItem
                        to="/terms"
                        component={Link}
                        style={{ cursor: "pointer", paddingLeft: "0px" }}
                      >
                        Terms & Conditions
                      </ListItem>
                      <ListItem
                        to="/privacy"
                        component={Link}
                        style={{ cursor: "pointer", paddingLeft: "0px" }}
                      >
                        Privacy Policy
                      </ListItem>
                    </List>{" "}
                  </Box>
                </Grid>
                <Grid item lg={3} sm={6} xs={6}>
                  <Box>
                    <Typography
                      className="footerTitle"
                      variant="h3"
                      style={{ color: "#ffffff" }}
                    >
                      My Account
                    </Typography>
                    <List className="listingFooter">
                      {user?.isLogin ? (
                        <>
                          {" "}
                          <ListItem
                            to="/profile"
                            component={Link}
                            style={{ cursor: "pointer", paddingLeft: "0px" }}
                          >
                            Profile
                          </ListItem>
                          <ListItem
                            to="/edit-profile"
                            component={Link}
                            style={{ cursor: "pointer", paddingLeft: "0px" }}
                          >
                            Edit Profile
                          </ListItem>
                        </>
                      ) : (
                        <>
                          {" "}
                          <ListItem
                            style={{ cursor: "pointer", color: "#D5D5D5" }}
                            onClick={toastmsgprofile}
                          >
                            Profile
                          </ListItem>
                          <ListItem
                            style={{ cursor: "pointer", color: "#D5D5D5" }}
                            onClick={toastmsg}
                          >
                            {" "}
                            Edit Profile
                          </ListItem>
                        </>
                      )}
                      {/* {account ? (
                        <>
                          {" "}
                          <ListItem to="/edit-profile" component={Link}>
                            Edit Profile
                          </ListItem>
                        </>
                      ) : (
                        <>
                          {" "}
                          <ListItem> Edit Profile</ListItem>
                        </>
                      )} */}
                    </List>{" "}
                  </Box>
                </Grid>
                <Grid item lg={3} sm={6} xs={6}>
                  <Box>
                    <Typography
                      className="footerTitle"
                      variant="h3"
                      style={{ color: "#ffffff" }}
                    >
                      Socials
                    </Typography>
                    <List
                      className="listingFooter"
                      style={{ marginTop: "28px" }}
                    >
                      <a href="https://www.facebook.com/" target="_blank">
                        {" "}
                        <FaFacebookF />
                      </a>
                      <a href="https://twitter.com/" target="_blank">
                        {" "}
                        <FaTwitter />
                      </a>
                      <a href="https://web.telegram.org/z/" target="_blank">
                        {" "}
                        <RiSendPlaneFill />
                      </a>
                      <a href="https://www.instagram.com/" target="_blank">
                        {" "}
                        <AiOutlineInstagram />
                      </a>
                    </List>{" "}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
