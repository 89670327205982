import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiTable: {
      root: {
        width: "100%",
        display: "table",
        borderSpacing: "0",
        background: " #15172d",
      },
    },

    MuiTableContainer: {
      root: {
        width: "auto",
        overflowX: "auto",
      },
    },

    MuiFormControl: {
      root: {
        border: "0",
        margin: "0",
        display: "inline-flex",
        padding: "0",
        position: "relative",
        minWidth: "0",
        flexDirection: "column",
        verticalAlign: "top",
        width: "100%",
      },
    },

    // MuiInput:{
    //   MuiInputUnderline::before: {
    //     left: "0",
    //     right: "0",
    //     bottom: "0",
    //     content: " ";
    //     position: "absolute",
    //     transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    //     borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    //     pointerEvents: "none",
    // },
    // },
    MuiFormLabel: {
      root: {
        color: "#fff",
        padding: "0",
        fontSize: "16px",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "400",
        lineHeight: "1",
      },
    },

    MuiInput: {
      "MuiInput-underline::before": {
        left: "0",
        right: "0",
        bottom: "0",
        content: " ",
        position: "absolute",
        transition:
          "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderBottom: "1px solid red",
        pointerEvents: "none",
      },
    },

    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: "#de337a",
      },
    },
    MuiDialog: {
      paper: {
        margin: "20px",
        position: "relative",
        // overflow-y: auto;
      },
      paperScrollPaper: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
      },
      paperWidthSm: {
        maxWidth: "500px",
        textAlign: "center",
      },
      paperWidthLg: {
        backgroundColor: "#2b2d49",
        color: "#fff",
      },
    },
    MuiTableCell: {
      body: {
        color: "#fff",
      },
      root: {
        padding: "7px 15px",
        borderBottom: " 1px solid rgb(43 45 73)",
        lineHeight: "1.43",
      },
    },
    MuiInputBase: {
      root: {
        color: "#fff",
        cursor: "text",
        display: "inline-flex",
        position: "relative",
        fontSize: "12px",
        boxSizing: "border-box",
        alignItems: "center",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "400",
        lineHeight: "1.1876em",
      },
      input: {
        padding: " 12px 0px",
        fontSize: "12px",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "10px !important",
      },
      notchedOutline: {
        borderColor: "rgb(235 224 224 / 23%)",
        background: "rgb(235 224 224 / 11%)",
        color: "#fff",
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#CC3C6E",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#cc3c6e",
      },
      day: {
        color: " #c3c5c8",
      },
    },

    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#383858",
      },
    },

    MuiSelect: {
      icon: { color: "rgb(21 23 45)", position: "unset" },
      select: {
        fontSize: "14px",
      },
    },
    MuiMenuItem: {
      root: {
        lineHeight: "14px",
        fontFamily: "'Orbitron', sans-seri",
        paddingBottom: "12px",
      },
    },

    MuiDialogContent: {
      root: {
        flex: "1 1 auto",
        padding: "0px",
        overflowY: "initial",
      },
    },

    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      root: {
        backgroundColor: "#15172d",
        color: "#ffffff",
      },
      rounded: {
        // borderRadius: "4px",
        backgroundColor: "#383858",
        // width: "100px",
        // padding: "10px 10px 10px 10px",
        // padding: "15px",
        // marginTop: "35px",
        // top: "90px !important",
        // "@media (max-width:1024px)":{
        //   top: "330px !important",
        // },
      },
    },
    // MuiList: {
    //   padding: {
    //     paddingTop: "12px",
    //   },
    // },
    MuiLinearProgress: {
      colorPrimary: {
        borderRadius: "5px",
        height: "7px",
      },
      barColorPrimary: {
        backgroundColor: "#de337a",
      },
    },
    MuiButton: {
      root: {
        color: "#52565c",
        padding: "6px 16px",
        /* font-size: 0.875rem; */
        fontSize: "12px",
        minWidth: "64px",
        boxSizing: "border-box",
        fontFamily: "'Orbitron', sans-seri",
        fontWeight: "500",
        lineHeight: "1.75",
        borderRadius: "4px",
        textTransform: "capitalize",
        "&.Mui-disabled": {
          color: "#ccc",
        },
      },

      outlinedPrimary: {
        borderRadius: "10px",
        color: "#fff",
        fontWeight: 600,
        border: "2px solid #8D8C8C ",
        boxSizing: "border-box",
        fontSize: "16px",
        minWidth: "120px",
        "&:hover": {
          background:
            " linear-gradient(272.26deg, #DC668F 36.78%, #DA4378 86.13%)",
          color: "#fff",
          border: "2px solid #DE337A ",
        },
      },
      containedPrimary: {
        borderRadius: "10px",
        color: "#fff",
        fontWeight: 600,
        border: "3px solid #CC3C6E ",
        boxSizing: "border-box",
        background:
          "linear-gradient(272.26deg, #DC668F 36.78%, #DA4378 86.13%)",
        fontSize: "16px",
        minWidth: "178px",
        "&:hover": {
          backgroundColor: "#1FA5A2",
          color: "#fff",
          border: "3px solid #CC3C6E ",
        },
      },
      containedSecondary: {
        borderRadius: "10px",
        color: "#fff",
        fontWeight: 600,
        border: "3px solid #24afac ",
        boxSizing: "border-box",
        background:
          " linear-gradient(272.26deg, #1FA5A2 36.78%, #0D807D 86.13%)",
        fontSize: "16px",
        // minWidth: "150px",
        "&:hover": {
          backgroundColor: "#DE337A",
          color: "#fff",
          border: "3px solid #24afac ",
        },
      },
    },
    MuiListItem: {
      gutters: {
        // padding: "10px 0 !important",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      body2: {
        fontSize: "0.875rem",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "400",
        lineHeight: "1.43",
        color: "#fff",
      },
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Roboto', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
