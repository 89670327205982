import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
  List,
  Link as routerLink,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import apiConfig, { socketURL } from "src/connectors/config/ApiConfig";
import { SUPPORTED_WALLETS } from "src/connectors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ListItem } from "@material-ui/core";

import { ToastContainer, toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";

import { useWeb3React } from "@web3-react/core";
import { UserContext } from "src/context/User";
// import { BsFillCaretDownFill } from "react-icons/bi";
import { BsFillCaretDownFill } from "react-icons/bs";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ConnectWallet from "src/component/ConnectWallet";
import { withStyles } from "@material-ui/core/styles";
const headersData = [
  {
    label: " Game",
    href: "/game",
  },
];
const headersDataright = [
  {
    label: "Marketplace",
    href: "/marketplace",
  },
  {
    label: "Metrics",
    href: "/reflection-metrics",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginRight: "10px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&:active": {
      color: "#DE337A",
    },
    "&:hover": {
      color: "#DE337A",
    },
  },
  metamaskhead: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    background: "#0b0c19",
    borderRadius: "14px",
    padding: "0px 0px 0px 12px",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "24px",
    "& h5": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "20px",
      textTransform: "capitalize",
      color: "#ffffff",
    },
    "&:hover": {
      background: "#383858",
    },
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    paddingLeft: "20px !important",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px ",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "0.4px",
    lineHeight: "1",
    color: "#fff",
    "&:hover": {
      color: "#da4378",
    },
    // borderBottom: "1px solid #3e3e3e",
    // padding: "16px !important",
    "@media (max-width:1024px)": {
      fontFamily: "'Roboto', sans-serif",
    },
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  menuMobilee: {
    "&:hover": {
      color: "#da4378",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "10px 0",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #ec0066",
    padding: "0 15px",
    background: "#ec0066",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "#fff",
      color: "#ec0066",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  btnBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& button": {
      marginLeft: "10px",
    },
  },
  stakemenuBox: {
    display: "flex",
    alignItems: "center",
    "& button": {
      padding: "0px 7px",
      fontSize: "14px",
      fontWeight: 500,
      // fontFamily: "'Roboto', sans-serif !important",
      "& svg": {
        marginLeft: "5px",
      },
      "&:hover": {
        color: "#DE337A",
      },
    },
  },
  btnresponsive: {
    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
    },
  },
  divstake: {
    ".MuiMenu-paper": {
      marginTop: "60px",
    },
  },
}));

export default function Header() {
  const { account, chainId } = useWeb3React();
  const user = useContext(UserContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const [open5, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    menuMobilee,
    menuMobile,
    divstake,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
    wallet,
    stakemenuBox,
    btnresponsive,
    btnBox,
    metamaskhead,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl1(null);
  };

  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };

  //menu
  const StyledMenu = withStyles({
    paper: {
      // border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
  //

  const OfflineUserHandler = async () => {
    try {
      const res = await axios.get(apiConfig.offlineUser, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      if (res.data.responseCode === 200) {
        DisconnectWallet();
        sessionStorage.removeItem("token");
      } else {
        toast.warn(res.data.responseMessage);
      }
    } catch (err) {
      console.log(err);
      toast.warn(err?.data?.responseMessage);
    }
  };

  const DisconnectWallet = () => {
    user.logoutHandler();
    toast.success("Disconnect Successful");
  };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open1);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open1 === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open1;
  // }, [open1]);

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Box className={stakemenuBox}>
                {getMenuButtons()}
                {stackmenu}
              </Box>
            </Grid>
            <Grid item xs={4} align="center">
              {femmecubatorLogo}
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                {getMenuButtonsRight()}
                {/* {connectBtn} */}

                {user?.isLogin ? (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      aria-haspopup="true"
                      onClick={handleClick3}
                      className={btnresponsive}
                      // onClick={() => user?.logoutHandler()}
                      // onClick={() => DisconnectWallet()}
                    >
                      {" "}
                      {account &&
                        `${account.substring(0, 4)}...${account.substring(
                          account.length - 4,
                          account.length
                        )}`}{" "}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      className={btnresponsive}
                      onClick={() => setOpen(true)}
                    >
                      Connect wallet
                    </Button>
                  </>
                )}
              </Box>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl3}
                keepMounted
                open={Boolean(anchorEl3)}
                onClose={handleClose3}
              >
                <MenuItem
                  style={{ lineHeight: "13px" }}
                  onClick={() => {
                    history.push("/profile");
                  }}
                  className={menuMobilee}
                >
                  Profile
                </MenuItem>

                <MenuItem
                  style={{ lineHeight: "13px" }}
                  onClick={OfflineUserHandler}
                  className={menuMobilee}
                >
                  Logout
                </MenuItem>
                {user?.userData?.userType == "Admin" && (
                  <MenuItem
                    style={{ lineHeight: "13px" }}
                    onClick={() => {
                      history.push("/dashboard");
                    }}
                    className={menuMobilee}
                  >
                    Dashboard
                  </MenuItem>
                )}

                {!sessionStorage.getItem("token") && (
                  <MenuItem
                    style={{ lineHeight: "13px" }}
                    onClick={() => {
                      history.push("/Login");
                    }}
                    className={menuMobilee}
                  >
                    Login
                  </MenuItem>
                )}
              </StyledMenu>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/footerlogo.png" alt="" />
            {getDrawerChoices()}
            {getDrawerChoicesRight()}
            {/* {stackmenu} */}

            <Grid item xs={4}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                // style={{ marginLeft: "15px", width: "180px" }}
                style={{ marginRight: "-63px", minWidth: "180px" }}
              >
                {user?.isLogin ? (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      aria-haspopup="true"
                      onClick={handleClick3}
                      className={btnresponsive}
                    >
                      {" "}
                      {account &&
                        `${account.substring(0, 4)}...${account.substring(
                          account.length - 4,
                          account.length
                        )}`}{" "}
                    </Button>
                    {/* </Box> */}
                  </>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      className={btnresponsive}
                      onClick={() => setOpen(true)}
                    >
                      Connect wallet
                    </Button>
                  </>
                )}
              </Box>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl3}
                keepMounted
                open={Boolean(anchorEl3)}
                onClose={handleClose3}
              >
                <MenuItem
                  style={{ lineHeight: "13px" }}
                  onClick={() => {
                    history.push("/profile");
                  }}
                  className={menuMobilee}
                >
                  Profile
                </MenuItem>

                <MenuItem
                  style={{ lineHeight: "13px" }}
                  onClick={OfflineUserHandler}
                  className={menuMobilee}
                >
                  Logout
                </MenuItem>
              </StyledMenu>
            </Grid>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "rgb(218 67 120)", fontSize: "50px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const getDrawerChoicesRight = () => {
    return headersDataright.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const connectBtn = (
    <Button
      variant="outlined"
      color="primary"
      size="large"
      className={btnresponsive}
    >
      Connect
    </Button>
  );
  const stackmenu = (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick1}
        className={menuMobile}
      >
        Stake <BsFillCaretDownFill />
      </Button>
      <Box className={divstake}>
        <StyledMenu
          id="customized-menu"
          disableScrollLock={true}
          anchorEl={anchorEl1}
          keepMounted
          open={Boolean(anchorEl1)}
          onClose={handleClose4}
        >
          <MenuItem
            onClick={() => {
              history.push("/coin-stake");
            }}
            className={menuMobile}
          >
            Coin Stake
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push("/nft-stake");
            }}
            className={menuMobile}
          >
            NFT Stake
          </MenuItem>
        </StyledMenu>
      </Box>
    </div>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  const getMenuButtonsRight = () => {
    return headersDataright.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#ccc0", border: "none" }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link to="/profile">My Profile</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/user">My Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/resell-nft">Resell Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/create-nft">Create NFT</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/notification">Notification</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/search">Search</Link>
        </MenuItem>
      </Menu>

      <ConnectWallet open={open5} onClose={() => handleClose()} />
    </>
  );
}
