import { toast } from "react-toastify";
export const NetworkContextName = "NETWORK";
export const Network = "binance";
export const ACTIVE_NETWORK = 97;

export const adminWalletAddress = "0xE8C852FB61a6350caa4a5301ECaEa4F5DF2eAdE9";

export const MarketplaceContractAddress =
  "0x12fEAbf824c7339b88fB61690097a4DfB7D38069"; // MarketplaceContract

export const deadAddress = "0x0000000000000000000000000000000000000000";
export const TokenAddress = "0xA2032241D167DA3f9027e8E841ac657C15695838";
export const TokenAddressSymbol = "NEW";
// export const contractAddress = "0x41e3A659443ed7ac8bb3a853A4D30167BBc37449";0x2f2F27EAB7A865f8F135E33735eD835711E27af3 //GenerativeNFTContract
export const contractAddress = "0x2f2F27EAB7A865f8F135E33735eD835711E27af3"; //safeSquid GenerativeNFTContract

export const stakingNFTContract = "0x1630dfAe6b3b911eA96599E33BA51f6A589168CD"; // Staking Contract
export const coinStakeContract = "0xc64e061BfB5BA81BD19C3D82203bBdDa62389506"; //Coin Stack Contract
export const ChartTokenBinance = "0x9a0b57175d92F628B657D8F11113Bdfff6435432"; //ChatContract
// export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
export const RPC_URL = "https://data-seed-prebsc-1-s3.binance.org:8545/";

export const LpStakeContract_7 = "0x924086eC3c7f1f0E687d309102F2c6e9169bEF26";
export const LpStakeContract_21 = "0x925F3e7Ba1595569F227094D9F18E9f1433705b9";
export const LpStakeContract_45 = "0xeAE371cFB37700Cc3E9B1ed87f9296709F0e2E83";
export const LpStakeContract_90 = "0x6E78ed10756a6fA80A73BDE1F110553BA386D5ED";

export const getLpStakeContract = (tab) => {
  switch (tab) {
    case "7_Days":
      return LpStakeContract_7;
    case "21_Days":
      return LpStakeContract_21;
    case "45_Days":
      return LpStakeContract_45;
    case "90_Days":
      return LpStakeContract_90;
  }
};

export const NetworkDetails = [
  {
    chainId: "97",
    chainName: "Smart Chain - Testnet",
    nativeCurrency: {
      name: "Smart Chain - Testnet",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://testnet.bscscan.com"],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
];
export const getMarketplaceContractAddress = (chianId) => {
  switch (chianId.toString()) {
    case "97":
      return MarketplaceContractAddress;
  }
};

export const getNetworkDetails = (chianId) => {
  switch (chianId.toString()) {
    case "42":
      return [
        {
          chainId: "0x2A",
          chainName: "Kovan Test Network",
          nativeCurrency: {
            name: "Kovan Test Network",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: [
            "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          ],
          blockExplorerUrls: ["https://kovan.etherscan.io"],
        },
      ];
    case "97":
      return [
        {
          chainId: "0x61",
          chainName: "Smart Chain - Testnet",
          nativeCurrency: {
            name: "Smart Chain - Testnet",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
          blockExplorerUrls: ["https://testnet.bscscan.com/"],
        },
      ];
  }
};

export const swichNetworkHandler = async (network) => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + parseFloat(network).toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);

      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  }
};
export const addNetworkHandler = async (network) => {
  const NetworkDetails = getNetworkDetails(network);
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: NetworkDetails,
    });
  } catch (error) {
    console.log("ERROR", error);
    toast.warn(error.message);
  }
};

export const gameType = ["Circle", "FrontMan", "Square", "Triangle"];

export const stakeTimeButtonData = [
  {
    name: "7 Days",
    key: "7_Days",
  },
  {
    name: "21 Days",
    key: "21_Days",
  },
  {
    name: "45 Days",
    key: "45_Days",
  },
  {
    name: "90Days",
    key: "90_Days",
  },
];
