import React, { createContext, useEffect, useMemo, useState } from "react";
import { injected } from "src/connectors";
import { SUPPORTED_WALLETS } from "src/connectors";

import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import apiConfig, { socketURL } from "src/connectors/config/ApiConfig";
import { toast } from "react-toastify";
import {
  contractAddress,
  NetworkContextName,
  addNetworkHandler,
  ACTIVE_NETWORK,
  getNetworkDetails,
} from "src/constants";

import GenerativeNFTABI from "src/constants/ABI/GenerativeNFTABI";

import Web3 from "web3";
import {
  getWeb3ContractObject,
  getWeb3Obj,
  getBalanceOf,
  getContract,
} from "src/utils";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    // sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [isLogin, setIsLogin] = useState(false);
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState();
  const [commonminted, setCommonMinted] = useState(false);
  const [rareMinted, setRareMinted] = useState(false);
  const [epicMinted, setEpicMinted] = useState(false);
  const [legendaryMinted, setLegendaryMinted] = useState(false);
  const [mythicMinted, setMythicMinted] = useState(false);
  const [userId, setuserId] = useState("");

  const [nftPrice, setNftPrice] = useState(0);
  const [MAX_NFT_SUPPLY, setMAX_NFT_SUPPLY] = useState(0);
  const [MAX_NFT_WALLET, setMAX_NFT_WALLET] = useState(0);
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [userNFTList, setUserNFTList] = useState([]);
  const [userNFtLoading, setUserNftLoading] = useState(false);
  const [saleIsActive, setSaleisActive] = useState();
  const [nftpricealll, setNftpricecommon] = React.useState("");

  const [myBatteleData, setMyBatteleData] = useState();
  const [gameId, setGameId] = useState("");
  const [activeRoundData, setActiveRoundData] = useState("");
  const [allTimers, setAllTimers] = useState();
  const [loserGameDetails, setLoserGameDetails] = useState();
  const [BNBpriceUSD, setBNBpriceUSD] = useState([]);
  const [nftTypeList, setNftTypeList] = useState([]);
  const [myBoostList, setMyBoostList] = useState([]);
  const [opponentBoosts, setOpponentBoosts] = useState([]);

  let data = {
    userData,
    opponentBoosts,
    myBoostList,
    nftTypeList,
    loserGameDetails,
    BNBpriceUSD,
    allTimers,
    isLoading,
    nftPrice,
    totalSupply,
    userNFTList,
    MAX_NFT_WALLET,
    balanceOfValue,
    MAX_NFT_SUPPLY,
    saleIsActive,
    commonminted,
    rareMinted,
    epicMinted,
    legendaryMinted,
    mythicMinted,
    nftpricealll,
    isLogin,
    myBatteleData,
    activeRoundData,
    setGameId: (id) => setGameId(id),
    updateUser: (account) => {
      setSession(account);
    },

    connectWallet: (data) => connectWalletHandler(data),
    getProfileHandler: (token) => getProfileHandler(token),
    OnlineUserHandler: (token) => OnlineUserHandler(token),
    getBoostStackingHandler: () => getBoostStackingHandler(),

    logoutHandler: () => {
      deactivate();
      setIsLogin(false);
      setUserData();
      sessionStorage.removeItem("token");
      localStorage.removeItem("walletName");
      sessionStorage.removeItem("userAddress");
      // setIsAdmin(false);
    },
  };

  const getBoostStackingHandler = async () => {
    try {
      const res = await axios.get(apiConfig.getBoostStacking, {
        params: {
          userId: userData?._id,
        },
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      if (res.data.responseCode == 200) {
        setMyBoostList(res.data.result.boostStackingId);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getBoostStackingOpponentHandler = async (userId) => {
    try {
      const res = await axios.get(apiConfig.getBoostStacking, {
        params: {
          userId: userId,
        },
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      if (res.data.responseCode == 200) {
        setOpponentBoosts(res.data.result.boostStackingId);
      }
    } catch (error) {
      // console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (myBatteleData?.playerId?.length > 0 && userData?._id) {
      const id =
        myBatteleData?.playerId[0] == userData?._id
          ? myBatteleData?.playerId[1]
          : myBatteleData?.playerId[0];
      getBoostStackingOpponentHandler(id);
    }
  }, [myBatteleData?.playerId, userData]);

  const listNftTypeHandler = async () => {
    try {
      const res = await axios.get(apiConfig.listNftType, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      if (res.data.responseCode == 200) {
        setNftTypeList(res.data.result);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    listNftTypeHandler();
    if (userData?._id) {
      getBoostStackingHandler();
    }
  }, [userData]);

  const myBNBpriceUSD = async () => {
    axios({
      method: "GET",
      url: "https://api.pancakeswap.info/api/v2/tokens/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    })
      .then(async (res) => {
        if (res.status === 200) {
          setBNBpriceUSD(res.data.data.price);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    myBNBpriceUSD();
  }, []);

  const getTimeHandler = async () => {
    try {
      const res = await axios.get(apiConfig.getTime, {
        params: {
          roomId: gameId,
        },
      });
      if (res.data.responseCode == 200) {
        setAllTimers(res.data.result);
      }
    } catch (error) {}
  };

  // activeRound-----
  useEffect(() => {
    const web = new WebSocket(socketURL);
    const accessToken = sessionStorage.getItem("token");
    if (accessToken && isLogin && gameId) {
      try {
        web.onopen = () => {
          const dataToSend = {
            requestType: "activeRound",
            roomId: gameId,
          };
          web.send(JSON.stringify(dataToSend));
          web.onmessage = async (event) => {
            let obj = JSON.parse(event.data);

            if (obj?.responseCode == 200) {
              console.log("activeRound obj", obj.result);

              setActiveRoundData(obj.result);
            } else {
              setActiveRoundData();
            }
          };
        };
      } catch (err) {}
    }
    return () => {
      web.close();
      console.log("activeRound----------useEffect-return");
    };
  }, [isLogin, gameId]);

  // myBattle-----
  useEffect(() => {
    const web = new WebSocket(socketURL);
    const accessToken = sessionStorage.getItem("token");
    if (accessToken && isLogin && gameId) {
      try {
        web.onopen = () => {
          const dataToSend = {
            requestType: "myBattle",
            token: accessToken,
            roomId: gameId,
          };

          web.send(JSON.stringify(dataToSend));
          web.onmessage = async (event) => {
            let obj = JSON.parse(event.data);
            if (obj?.responseCode == 200) {
              console.log("myBattle obj", obj.result);
              setMyBatteleData(obj.result);
              setLoserGameDetails();
            } else if (obj?.responseCode == 201) {
              setMyBatteleData();
              setLoserGameDetails();
            } else if (obj?.responseCode == 202) {
              setMyBatteleData();
              setLoserGameDetails(obj);
            } else if (obj?.responseCode == 203) {
              setMyBatteleData();
              setLoserGameDetails(obj);
            } else {
              setMyBatteleData();
              setLoserGameDetails();
            }
          };
        };
      } catch (err) {
        console.log("obj myBattle err", err);
      }
    }
    return () => {
      web.close();
      console.log("myBattle----------useEffect-return");
    };
  }, [isLogin, gameId]);

  //function

  const mintUnCommonHandler = async () => {
    try {
      const contractObj = getContract(
        contractAddress, //Genetrativeaddress
        GenerativeNFTABI,
        library,
        account
      );

      const nftPricecommon = await contractObj.NFT_PRICE_COMMON();

      setNftpricecommon(nftPricecommon);
      const commonMinted = await contractObj.isCommonMinted(account);
      setCommonMinted(commonMinted);
      const rareMinted = await contractObj.isRareMinted(account);
      setRareMinted(rareMinted);
      const epicMinted = await contractObj.isEpicMinted(account);
      setEpicMinted(epicMinted);
      const legendaryMinted = await contractObj.isLegendaryMinted(account);
      setLegendaryMinted(legendaryMinted);
      const mythicMinted = await contractObj.isMythicMinted(account);
      setMythicMinted(mythicMinted);
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  useEffect(() => {
    mintUnCommonHandler();
    if (gameId) {
      getTimeHandler();
    }
  }, [account, gameId]);

  //wallet
  const getUserbalce = async () => {
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  const getContractDetailsHandler = async () => {
    try {
      setIsLoadingData(true);
      const web3 = await getWeb3Obj();
      const contractObj = await getWeb3ContractObject(
        GenerativeNFTABI,
        contractAddress
      );

      const MAX_NFT_WALLET = await contractObj.methods.MAX_NFT_WALLET().call();
      setMAX_NFT_WALLET(Number(MAX_NFT_WALLET));
      setIsLoadingData(false);
      // } else {
      //   setNftPrice(getRESERVE_PRICE);
      // }
    } catch (err) {
      console.log(err);
      setIsLoadingData(false);
    }
  };

  // const mintUnCommonHandlerr = async () => {
  //   const contractObj = getContract(
  //     contractAddress, //Genetrativeaddress
  //     GenerativeNFTABI,
  //     library,
  //     account
  //   );
  //   console.log("datataa++++++", contractObj);

  //   const nftPricecommon = await contractObj.NFT_PRICE_COMMON();
  //   console.log("nftPricecommon");
  //   setNftpricecommon(nftPricecommon);
  // };

  // useEffect(() => {
  //   mintUnCommonHandlerr();
  // }, []);

  const getCurrentMintingDetails = async () => {
    const contractObj = await getWeb3ContractObject(
      GenerativeNFTABI,
      contractAddress
    );
    // if (account) {
    //   getBalanceOfFun();
    // }
    const MAX_NFT_SUPPLY = await contractObj.methods.MAX_NFT_SUPPLY().call();
    setMAX_NFT_SUPPLY(Number(MAX_NFT_SUPPLY));
    const totalSupply = await contractObj.methods.totalSupply().call();
    setTotalSupply(Number(totalSupply));
  };

  useEffect(() => {
    getContractDetailsHandler();
    getCurrentMintingDetails();
  }, []);

  const saleIsActivenft = async () => {
    try {
      const web3 = await getWeb3Obj();
      const contractObj = await getWeb3ContractObject(
        GenerativeNFTABI,
        contractAddress
      );
      console.log("contractObj", contractObj);

      // if (hasFinalSaleStarted) {
      const saleIsActive = await contractObj.methods.saleIsActive().call();

      setSaleisActive(saleIsActive);
    } catch (err) {
      console.log(err);
      // setIsLoadingData(false);
    }
  };
  useEffect(() => {
    saleIsActivenft();
    getContractDetailsHandler();
  }, []);

  const userNFTListHadler = async (balanceOf) => {
    setUserNFTList([]);
    setUserNftLoading(true);
    const contract = getContract(
      contractAddress,
      GenerativeNFTABI,
      library,
      account
    );

    try {
      for (let i = 0; i < balanceOf; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        const filter = await contract.tokenURI(id.toString());

        const res = await axios.get(filter);
        if (res.status === 200) {
          setUserNFTList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ]);
          setUserNftLoading(false);
        }
      }
    } catch (e) {
      console.log(e);
      setUserNftLoading(false);
    }
  };

  // useEffect(() => {
  //   if (balanceOfValue > 0) {
  //     userNFTListHadler(balanceOfValue);
  //   }
  // }, [balanceOfValue, account]);

  useEffect(() => {
    if (account) {
      getUserbalce();
    }
  }, [account, library]);
  useEffect(() => {
    if (account) {
      getBalanceOfFun();
    }
  }, [account]); //eslint-disable-line

  async function getBalanceOfFun() {
    setBalanceOfValue(
      await getBalanceOf(GenerativeNFTABI, contractAddress, account)
    );
  }

  //OnlineUser

  const OnlineUserHandler = async (token) => {
    const res = await axios.get(apiConfig.onlineUser, {
      headers: {
        token,
      },
    });
    if (res.data.responseCode === 200) {
      // const list = res.data.result.docs.filter(
      //   (data) => data.isPlace === false && data.isStack !== true
      // );
    }
  };
  // const OfflineUserHandler = async () => {
  //   const res = await axios.get(apiConfig.offlineUser, {
  //     headers: {
  //       token: sessionStorage.getItem("token"),
  //     },
  //   });
  //   if (res.data.responseCode === 200) {
  //     // const list = res.data.result.docs.filter(
  //     //   (data) => data.isPlace === false && data.isStack !== true
  //     // );
  //     console.log("res.data.result12", res.data.result);
  //   }
  // };

  // useEffect(() => {
  //   // OfflineUserHandler();
  //   if (userId) {
  //     OnlineUserHandler();
  //   }
  // }, [account]);

  const connectWalletHandler = (data) => {
    try {
      const connector = data.connector;

      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          localStorage.removeItem("walletName");
          activate(connector);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.error(JSON.stringify(error.message));
    }
  };

  const connectWalletAPIHandler = async (walletAddress) => {
    try {
      const res = await axios.post(apiConfig.connectWallet, {
        walletAddress,
      });
      if (res.data.responseCode === 200) {
        getProfileHandler(res.data.result.token);
        OnlineUserHandler(res.data.result.token);
        setuserId(res.data.result.userId);
        setTokenSession(res.data.result.token);
      } else {
        deactivate();
        setIsLogin(false);
        setUserData();
        setIsAdmin(false);
        setIsLoading(false);
      }
    } catch (error) {
      deactivate();
      setIsLogin(false);
      console.log("ERROR", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (account && chainId) {
      if (chainId != ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  const getProfileHandler = async (token) => {
    try {
      const res = await axios.get(apiConfig.profile, {
        headers: {
          token,
        },
      });
      if (res.data.responseCode === 200) {
        setUserData(res.data.result);

        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLogin(false);
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name == localStorage.getItem("walletName")
      );

      if (selectectWalletDetails.length > 0) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    }
  }, []);

  useEffect(() => {
    if (account) {
      connectWalletAPIHandler(account);
    } else {
      setIsLogin(false);
      setUserData();
      setIsAdmin(false);
    }
  }, [account]);

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
