import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

// import Accordian from "./Accordian";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/reflection-metrics",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/ReflectionMetrics/Index")),
  },
  {
    exact: true,
    path: "/marketplace",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Marketplace/Index")),
  },
  {
    exact: true,
    path: "/players",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/GameTypes")),
  },
  // {
  //   exact: true,
  //   path: "/game-world",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Home/GameWorld")),
  // },
  {
    exact: true,
    path: "/game",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Game/Index")),
  },
  {
    exact: true,
    path: "/game-main",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/admin/GameMain")),
  },
  {
    exact: true,
    path: "/game-main-edit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/admin/GameMainEdit")),
  },
  {
    exact: true,
    path: "/add-stake-boost",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/tournament/stakeBoost")
    ),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/admin/dashboard/Dashboard")),
  },
  {
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/usermangement/UserManagement")
    ),
  },
  {
    exact: true,
    path: "/ViewUser",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/usermangement/ViewUser")
    ),
  },
  {
    exact: true,
    path: "/nft-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/nftmanagement/NftManagement")
    ),
  },
  {
    exact: true,
    path: "/game-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/gamemanagement/GameManagement")
    ),
  },
  {
    exact: true,
    path: "/transaction-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/admin/transactionmanagement/TransactionManagement"
      )
    ),
  },
  {
    exact: true,
    path: "/placeorderlistdetails",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/transactionmanagement/PlaceOrderDetails")
    ),
  },
  {
    exact: true,
    path: "/static-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/staticmanagement/StaticMangement")
    ),
  },
  {
    exact: true,
    path: "/faq-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/faqmanagement/FaqManagement")
    ),
  },
  {
    exact: true,
    path: "/view-social",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/staticmanagement/ViewSocial")
    ),
  },
  {
    exact: true,
    path: "/tournament",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/tournament/Tournament")
    ),
  },
  {
    exact: true,
    path: "/add-tournament",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/tournament/AddTournament")
    ),
  },
  {
    exact: true,
    path: "/commision",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/admin/commision/Commision")),
  },
  {
    exact: true,
    path: "/view-reported-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/nft-reported/NftReportedDetails")
    ),
  },
  {
    exact: true,
    path: "/sub-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/subadmin/SubAdminList")
    ),
  },
  {
    exact: true,
    path: "/add-sub-admin",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/admin/subadmin/AddSubAdmin")),
  },
  {
    exact: true,
    path: "/nft-report",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/nft-reported/NftReportedList")
    ),
  },
  {
    exact: true,
    path: "/main-game",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Game/Main")),
  },
  {
    exact: true,
    path: "/game-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Game/GameDetails")),
  },

  {
    exact: true,
    path: "/round-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Round/RoundDetails")),
  },

  {
    exact: true,
    path: "/mini-game",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Game/Mini")),
  },
  {
    exact: true,
    path: "/mint",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Mint/Index")),
  },
  {
    exact: true,
    path: "/my-players",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Mint/MyplayersList")),
  },
  {
    exact: true,
    path: "/nft-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NFTDetails/index")),
  },

  {
    exact: true,
    path: "/play-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Player/Player")),
  },

  {
    exact: true,
    path: "/nft-stake2",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Staking/Index")),
  },
  {
    exact: true,
    path: "/nft-stake",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NFTCoin/Index")),
  },

  // {
  //   exact: true,
  //   path: "/game",
  //   layout: DashboardLayout,
  //   // component: lazy(() => import("src/views/pages/Creator/index")),
  // },

  // {
  //   exact: true,
  //   path: "/coin-stake",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Coin/Index")),
  // },
  {
    exact: true,
    path: "/coin-stake",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/CoinStake/Index")),
  },
  {
    exact: true,
    path: "/marketplace",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Marketplace/Index")),
  },
  {
    exact: true,
    path: "/profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/Profile")),
  },
  {
    exact: true,
    path: "/Login",

    component: lazy(() => import("src/views/pages/Login/Login")),
  },
  {
    exact: true,
    path: "/Forgot",

    component: lazy(() => import("src/views/pages/Login/Forgot")),
  },
  {
    exact: true,
    path: "/Verify-otp",

    component: lazy(() => import("src/views/pages/Login/Verify-otp")),
  },
  {
    exact: true,
    path: "/ResetPassword",

    component: lazy(() => import("src/views/pages/Login/ResetPassword")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  },
  // {
  //   exact: true,
  //   path: "/edit-profile",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  // },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About")),
  },
  {
    exact: true,
    path: "/terms",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Terms")),
  },
  {
    exact: true,
    path: "/team",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Team")),
  },
  {
    exact: true,
    path: "/privacy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Privacy")),
  },
  // {
  //   exact: true,
  //   path: "/edit-profile",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  // },
  {
    exact: true,
    path: "/resell-nft",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/ResellNFT/Index")),
  },

  {
    exact: true,
    path: "/game-boost",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/tournament/GameBootTextfield")
    ),
  },
  {
    exact: true,
    path: "/gameboosttable",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/admin/tournament/GameBoostTable")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
